// 新闻企业资讯
<template>
  <div class="content">
    <div class="imgbox">
      <!-- <img src="../assets/about/banner01.png" alt="" /> -->
      <div >{{ img.cont }}</div>
      <!-- <div v-show="lang == '1'">{{ img.contEn }}</div> -->
    </div>
    <div class="newlist" id="newlist">
      <div style="margin-top: 100px; display: flex">
        <p
          style="font-size: 14px; line-height: 21px"
          @click="go()"
          class="goback"
        >
          返回 |
        </p>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin-left: 10px; line-height: 21px"
        >
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>
          <el-breadcrumb-item>企业资讯</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="title">
        <span></span>
        <h2>新闻列表</h2>
        <!-- <span v-show="lang == '1'">News list</span> -->
        <div class="link">
         <a href="#/news">全部</a>
          <a href="#/marketNews">市场趋势</a>
          <a href="#/professionNews">行业动态</a>
          <a href="#/companyNews">企业资讯</a>
          <a href="#/productNews">产品信息</a>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in newsList" :key="index">
          <div class="left_img">
            <img :src="item.cnImg" alt="新闻图片" />
          </div>
          <div class="right_text">
            <p>{{ item.createTime.slice(0, 10) }}</p>
            <h3>{{ item.cnTit }}</h3>
            <!-- <p v-html="item.cnContent.split('<br/>')[0] | filterHtmlTag"></p> -->
            <p style="margin-top: 40px">{{ item.cnContent | filterHtmlTag }}</p>
            <p />
            <!-- <span v-html=" item.cnContent "></span>  -->
            <!-- <div
            class="p1"
              style="margin-top: 35px"
              v-html="item.cnContent.split('<br/>')[0]"
            ></div> -->
            <!-- <button @click="goDetail(item)">了解详情></button> -->
            
            <a :href="`#/NewsDetail/${item.cnId}`">
              <button>了解详情 ></button>
            </a>

          </div>
        </div>
        <el-pagination
          ref="Pagination"
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="4"
          :total="total"
          @next-click="nextPage"
          @prev-click="prevPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    metaInfo: {
    title: '新闻资讯-企业资讯-东营市旭瑞智能科技股份有限公司',  // 设置title
    meta: [{                 
      name: 'keywords',  // 设置关键字
      content: '智慧水利，PLC控制柜，闸泵远程控制，智慧城市，智慧城管，智慧水务，城市防汛监测，智慧闸泵，智慧环卫，智慧公厕，智慧园林，智慧执法，智慧渣土，市容秩序提升，智慧工地，智慧道桥，智慧井盖，智慧管网，智慧照明，智慧农业，智能灌溉，水肥一体化，物联网'
    },
    {
      name: 'description',  // 设置页面描述
      content: '旭瑞智能专注于智慧水利、智慧城管、智慧农业及智能化工程等智慧物联网细分领域软硬件产品研发、生产、销售、运营，提供智慧行业整体解决方案。'
    }],
  },
  filters: {
    filterHtmlTag(t) {
      if (!t) return "";
      return t.toString().replace(/<.*?>/g, "");
    },
  },
  data() {
    return {
      newsList: [],
      total: null,
      Picurl: "http://101.200.43.114:8710/jeecg-boot/sys/common/static/",
      page: 1,
      size: 4,
      lang: "",
      img: {},
      Consultation: "",
      current: 2,
    };
  },
  updated() {
    if (this.$route.query.name) {
      this.$el.querySelector(this.$route.query.name).scrollIntoView();
    }
  },
  created() {
    this.lang = localStorage.getItem("newv");
    this.getNews(this.page, this.size);
    this.getArticleListAll();
    // if(this.newsList.cnContent == " ") {
    //   this.router.go(0)
    // }
    // this.total =
  },
  mounted() {},
  methods: {
    go() {
      this.$router.go(-1);
    },
    getArticleListAll() {
      let data = {type:3};
      this.$API.getArticleListAll(data).then((res) => {
        // console.log(res);
        // console.log(res.data.length);
        this.total = res.data.length;
      });
    },
    // 新闻资讯
    getNews() {
      let data = {
        pageNum: this.page,
        pageSize: 4,
        type:3
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        this.newsList = res.data.list;
        // this.newsList[1].cnImg = require("../assets/news/zp.png");
        // this.newsList[1].cnContent = this.newsList[1].cnContent.slice(3, 7);
        // this.total = res.data.total;
        // this.newsList.map((item, index) => {
        //   console.log(item);
        // item.cnContent = item.cnContent.split("&nbsp;")[0];
        // });
      });
    },
    handleCurrentChange(val) {
      // this.getNewslist({val}, this.size);
      console.log({ val }.val);
      let data = {
        pageNum: { val }.val,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        this.newsList = res.data.list;
        // this.newsList[1].cnImg = require("../assets/news/zp.png");
        // this.newsList[1].cnContent = this.newsList[1].cnContent.slice(3, 7);
        this.total = res.data.total;
        this.newsList.map((item, index) => {
          console.log(item);
          // item.cnContent = item.cnContent.split("&nbsp;")[0];
        });
      });
      document.querySelector("#newlist").scrollIntoView(true);
      // this.$router.go(0)
    },
    goDetail(val) {
      console.log(val.cnId);
      let id = val.cnId;
      this.$router.push({ path: `/NewsDetail/${id}` });
    },
    //上一页
    prevPage() {
      document.querySelector("#newlist").scrollIntoView(true);
      let data = {
        pageNum: --this.page,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        this.newsList = res.data.list;
        // this.newsList[1].cnImg = require("../assets/news/zp.png");
        // this.newsList[1].cnContent = this.newsList[1].cnContent.slice(3, 7);
        this.total = res.data.total;
        this.newsList.map((item, index) => {
          console.log(item);
          item.cnContent = item.cnContent.split("&nbsp;")[0];
        });
      });
      // this.$router.go(0);
    },
    //下一页
    nextPage() {
      document.querySelector("#newlist").scrollIntoView(true);
      if (this.pageNum <= this.size) {
        this.pageNum = this.page;
      }
      let data = {
        pageNum: ++this.page,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        this.newsList = res.data.list;
        // this.newsList[1].cnImg = require("../assets/news/zp.png");
        // this.newsList[1].cnContent = this.newsList[1].cnContent.slice(3, 7);
        this.total = res.data.total;
        this.newsList.map((item, index) => {
          console.log(item);
          item.cnContent = item.cnContent.split("&nbsp;")[0];
        });
      });
      //  this.$router.go(0)
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-breadcrumb__inner.is-link {
  font-weight: normal;
}
/deep/.el-breadcrumb__inner.is-link:hover {
  color: #0249a1;
}
.goback:hover {
  color: #0249a1;
  cursor: pointer;
}
h2 {
  font-size: 34px;
  margin-left: 10px;
}
h3 {
  font-size: 24px;
  margin-top: 30px;
}
.content {
  width: 100%;
  background-color: #fff;
  .imgbox {
    width: 100%;
    // height: 600px;
    margin-bottom: 50px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 28px;
      color: #fff;
    }
  }
  .newlist {
    width: 60%;
    // height:436px;
    margin: 0 auto 50px;
    // cursor: pointer;
    .title {
      width: 100%;
      height: 65px;
      display: flex;
      padding-top: 50px;
      align-items: center;
      span:nth-child(1) {
        display: inline-block;
        width: 8px;
        height: 30px;
        background-color: #004aa1;
      }
      span:nth-child(2) {
        font-size: 34px;
        font-weight: 600;
        margin-left: 10px;
      }
      span:nth-child(3) {
        font-size: 20px;
        color: #666666;
        font-weight: 600;
        margin-left: 10px;
      }
      .link {
        display: flex;
        margin-left: 10%;
        a {
          margin-right: 20px;
          color: #000;
          // font-weight: bold;
        }
        a:hover {
          color: #0249a1;
        }
        a:nth-child(4) {
          color: #0249a1;
        }
      }
    }
    .list {
      .el-pagination {
        text-align: center;
      }
      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed #999;
        padding: 30px 0;
        .left_img {
          width: 50%;
          img {
            width: 100%;
            height: 350px;
            border-radius: 10px;
          }
        }
        .right_text {
          width: 47%;
          height: 300px;
          padding: 50px 0;
          position: relative;
          /deep/ .p1 {
            p:nth-child(2) {
              width: 0px;
            }
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          p:nth-child(1) {
            font-size: 20px;
          }
          p:nth-child(2) {
            font-size: 24px;
            font-weight: 600;
            margin-top: 30px;
            -webkit-line-clamp: 2;
          }
          p:nth-child(3) {
            margin-top: 30px;
            -webkit-line-clamp: 4;
          }
          button {
            position: absolute;
            bottom: 30px;
            cursor: pointer;
            border: 1px solid #004aa1;
            width: 150px;
            height: 40px;
            border-radius: 20px;
            background-color: #fff;
            font-weight: 600;
            font-size: 17px;
            color: #004aa1;
          }
          a:hover {
            button {
              background-color: #004aa1;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>